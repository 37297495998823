<template>
  <!-- header start -->
  <header id="header" class="header-wrapper home-parallax home-fade">
    <div class="header-overlay"></div>
    <div class="header-wrapper-inner">
      <div class="container">
        <div class="welcome-speech">
          <h1>Bring your brand online and sell more!</h1>
          <p>
            Everything you need to build your brand and show it to the world.
          </p>
          <a href="#" class="btn btn-white"> Our projects </a>
        </div>
        <!-- /.intro -->
      </div>
      <!-- /.container -->
    </div>
    <!-- /.header-wrapper-inner -->
  </header>
  <!-- /#header -->
</template>

<script>
export default {
  name: "HeaderHomeSection",
};
</script>
